import * as React from "react"
import WithRoot from "./withRoot"
import { getUserProgress, getCurrentUser, lastUserProgress, getNextVideo, getVideoSlug } from "../utils/modelService"
import { navigate } from "gatsby"
import config from "../config"

const CoursePage = () => {
  React.useEffect(() => {
    actionNavigation()
  }, [])

  const actionNavigation = async () => {
    const user = await getCurrentUser()
    if (!user) {
      // navigate(`/sign-in?source=/${config.firstCourseSlug}`)
      navigate(config.firstCourseSlug)
      return
    }
    const userProgress = getUserProgress()
    if (userProgress.length === 0) {
      navigate(config.firstCourseSlug)
      return
    }
    const last = lastUserProgress(userProgress)
    const nextVideo = getNextVideo(last.moduleId, last.videoOrder)
    navigate("/" + getVideoSlug(nextVideo.moduleId, nextVideo.title))
  }

  return null
}

export default WithRoot(CoursePage)
